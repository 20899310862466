// Import and register all your controllers from the importmap via controllers/**/*_controller
import { Application } from "@hotwired/stimulus"
import NavigationController from "./navigation_controller"
import CheckoutController from "./checkout_controller"
import RegistrationSlideshowController from "./registration_slideshow_controller"
import EditModeController from "./edit_mode_controller"
import DesignController from "./design_controller"

const application = Application.start()
application.register("navigation", NavigationController)
application.register("checkout", CheckoutController)
application.register("registration-slideshow", RegistrationSlideshowController)
application.register("edit-mode", EditModeController)
application.register("design", DesignController)
eagerLoadControllersFrom("controllers", application)
